import React, { useState } from "react";
import Landing from "./Landing";
import Viewer from "./Viewer";

const App = () => {
  const [file, setFile] = useState();

  if (!file) {
    return <Landing setFile={setFile} />;
  } else {
    return <Viewer file={file} setFile={setFile} />
  }
};

export default App;
