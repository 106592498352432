import React, { useState, useEffect } from "react";
import Plotly from "plotly.js-cartesian-dist";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Slider from "@material-ui/core/Slider";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FileHeaders from "./FileHeaders";
import Settings from "./Settings";

const MIN_PERCENTILE = 80;
const MAX_PERCENTILE = 100;
const PERCENTILE_STEP = 0.1;

const getPercentileValues = (data) => {
  const min = [];
  const max = [];
  data.sort();
  for (
    let percentile = MIN_PERCENTILE;
    percentile <= MAX_PERCENTILE;
    percentile += PERCENTILE_STEP
  ) {
    min.push(data[Math.floor((data.length * (100 - percentile)) / 100)]);
    max.push(data[Math.floor((data.length * percentile) / 100)]);
  }
  return [min, max];
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const plottable = (nSamples, nTracesPerEnsemble) => {
  return (
    nSamples >= 2 &&
    nSamples <= 20000 &&
    nTracesPerEnsemble >= 2 &&
    nTracesPerEnsemble <= 20000
  );
};

const Viewer = ({ file, setFile }) => {
  const [data2d, setData2d] = useState();
  const [ensemble, setEnsemble] = useState(0);
  const [percentileValues, setPercentileValues] = useState();
  const [percentile, setPercentile] = useState(99);
  const [showFileHeaders, setShowFileHeaders] = useState(false);
  const [showSettings, setShowSettings] = useState(() =>
    !plottable(file.nSamples, file.nTracesPerEnsemble)
  );
  const [colormap, setColormap] = useState("Greys");
  const [loading, setLoading] = useState(true);
  const sampleInterval = file.sampleInterval;
  const littleEndian = file.littleEndian;
  const nSamples = file.nSamples;
  const dataFormat = file.dataFormat;
  const nTracesPerEnsemble = file.nTracesPerEnsemble;
  const nEnsembles = file.nEnsembles;
  const classes = useStyles();

  useEffect(() => {
    Plotly.newPlot(
      "plot_div",
      [
        {
          type: "heatmap",
          colorscale: colormap,
          showscale: false,
        },
      ],
      {
        margin: { l: 0, r: 0, t: 0, b: 0 },
        xaxis: { side: "top", automargin: true },
        yaxis: { automargin: true },
      },
      {
        scrollZoom: true,
        toImageButtonOptions: {
          format: "jpeg",
        },
        modeBarButtonsToRemove: [
          "select2d",
          "lasso2d",
          "zoomIn2d",
          "zoomOut2d",
          "autoScale2d",
          "hoverClosestCartesian",
          "hoverCompareCartesian",
          "toggleSpikelines",
        ],
        displaylogo: false,
        responsive: true,
      }
    );
  }, [colormap]);

  useEffect(() => {
    setLoading(true);
    const traceStart = ensemble * nTracesPerEnsemble;
    const traceStop = traceStart + nTracesPerEnsemble;
    if (plottable(file.nSamples, file.nTracesPerEnsemble)) {
      file.readTracesData(traceStart, traceStop, 1).then((traceData) => {
        const nTraces = traceData.length / nSamples;
        const traceData2d = [];
        for (let sampleId = 0; sampleId < nSamples; sampleId++) {
          const row = [];
          for (let traceId = 0; traceId < nTraces; traceId++) {
            row.push(traceData[traceId * nSamples + sampleId]);
          }
          traceData2d.push(row);
        }
        setData2d(traceData2d);
        setPercentileValues(getPercentileValues(traceData));
      });
    } else {
      setShowSettings(true);
    }
  }, [ensemble, nTracesPerEnsemble, nSamples, file, littleEndian, dataFormat]);

  useEffect(() => {
    Plotly.update(
      "plot_div",
      { dy: sampleInterval },
      {
        "xaxis.range": [0, nTracesPerEnsemble],
        "yaxis.range": [nSamples * sampleInterval, 0],
      }
    );
  }, [sampleInterval, nTracesPerEnsemble, nSamples, colormap]);

  useEffect(() => {
    if (data2d) {
      Plotly.restyle("plot_div", { z: [data2d] });
      setLoading(false);
    }
  }, [data2d]);

  useEffect(() => {
    if (percentile && percentileValues) {
      const i = Math.round((percentile - MIN_PERCENTILE) / PERCENTILE_STEP);
      Plotly.restyle("plot_div", {
        zmin: percentileValues[0][i],
        zmax: percentileValues[1][i],
      });
    }
  }, [percentile, percentileValues, colormap]);

  const handleFileHeaders = () => {
    setShowFileHeaders(true);
  };

  const handleSettings = () => {
    setShowSettings(true);
  };

  return (
    <>
      <Backdrop open={loading} className={classes.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid
        container
        direction="column"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Grid item>
          <Container maxWidth="lg">
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item style={{ flex: 1 }}>
                <Ensemble
                  ensemble={ensemble}
                  setEnsemble={setEnsemble}
                  nEnsembles={nEnsembles}
                />
              </Grid>
              <Grid item style={{ flex: 1 }}>
                <Percentile
                  percentile={percentile}
                  setPercentile={setPercentile}
                />
              </Grid>
              <Grid item>
                <Button onClick={handleFileHeaders}>File headers</Button>
                <FileHeaders
                  showFileHeaders={showFileHeaders}
                  setShowFileHeaders={setShowFileHeaders}
                  file={file}
                />
              </Grid>
              <Grid item>
                <Button onClick={handleSettings}>Settings</Button>
                <Settings
                  showSettings={showSettings}
                  setShowSettings={setShowSettings}
                  file={file}
                  setFile={setFile}
                  colormap={colormap}
                  setColormap={setColormap}
                />
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid
          item
          id="plot_div"
          overflow="hidden"
          style={{ flex: 1, height: "100%" }}
        />
      </Grid>
    </>
  );
};

function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

const Ensemble = ({ ensemble, setEnsemble, nEnsembles }) => {
  const [ensembleTmp, setEnsembleTmp] = useState(ensemble);

  const debouncedEnsemble = useDebounce(ensembleTmp, 200);

  useEffect(() => {
    setEnsemble(debouncedEnsemble);
  }, [setEnsemble, debouncedEnsemble]);

  const handleSliderChange = (event, newValue) => {
    setEnsembleTmp(newValue);
  };

  const handlePrevEnsemble = () => {
    setEnsembleTmp((e) => e - 1);
  };

  const handleNextEnsemble = () => {
    setEnsembleTmp((e) => e + 1);
  };

  return (
    <div>
      <Typography
        id="ensemble"
        /*variant="caption"*/ gutterBottom
        align="center"
      >
        Ensemble
      </Typography>
      <Grid container alignItems="center" spacing={0}>
        <Grid item>
          <IconButton
            aria-label="previous ensemble"
            disabled={ensemble === 0}
            onClick={handlePrevEnsemble}
            size="small"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Slider
            min={0}
            max={nEnsembles - 1}
            step={1}
            value={typeof ensembleTmp === "number" ? ensembleTmp : 0}
            valueLabelDisplay="auto"
            onChange={handleSliderChange}
            aria-labelledby="ensemble"
            style={{ height: "0" }}
          />
        </Grid>
        <Grid item>
          <IconButton
            aria-label="next ensemble"
            disabled={ensemble === nEnsembles - 1}
            onClick={handleNextEnsemble}
            size="small"
            style={{ paddingTop: "0", paddingBottom: "0" }}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

const Percentile = ({ percentile, setPercentile }) => {
  const [percentileTmp, setPercentileTmp] = useState(percentile);

  const debouncedPercentile = useDebounce(percentileTmp, 100);

  useEffect(() => {
    setPercentile(debouncedPercentile);
  }, [setPercentile, debouncedPercentile]);

  const handleChange = (e, newValue) => {
    setPercentileTmp(newValue);
  };

  return (
    <div>
      <Typography
        id="percentile"
        /*variant="caption"*/ gutterBottom
        align="center"
      >
        Clip
      </Typography>
      <Slider
        min={MIN_PERCENTILE}
        max={MAX_PERCENTILE}
        step={PERCENTILE_STEP}
        value={percentileTmp}
        valueLabelDisplay="auto"
        onChange={handleChange}
        aria-labelledby="percentile"
      />
    </div>
  );
};

export default Viewer;
