import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import SelectFile from "./SelectFile";

const Landing = ({ setFile }) => {
  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid
          container
          item
          style={{ flex: 1 }}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <SelectFile setFile={setFile} />
          <Typography
            variant="caption"
            align="center"
            display="block"
            gutterBottom
          >
            Everything runs locally on your computer - nothing is uploaded.
            Files are not modified.
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            align="center"
            display="block"
            gutterBottom
          >
            I love to hear from users. <Link href="mailto:alan@ausargeo.com">Say hello!</Link>.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default Landing;
