import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Alert from '@material-ui/lab/Alert';
import Segy from "./Segy";

export default function Settings({
  showSettings,
  setShowSettings,
  file,
  setFile,
  colormap,
  setColormap,
}) {
  const [nSamplesTmp, setNSamplesTmp] = useState(file.nSamples);
  const [nTracesPerEnsembleTmp, setNTracesPerEnsembleTmp] = useState(
    file.nTracesPerEnsemble
  );
  const [nExtendedTextualHeadersTmp, setNExtendedTextualHeadersTmp] = useState(
    file.nExtendedTextualHeaders
  );
  const [nTraceHeadersTmp, setNTraceHeadersTmp] = useState(file.nTraceHeaders);
  const [littleEndianTmp, setLittleEndianTmp] = useState(file.littleEndian);
  const [dataFormatTmp, setDataFormatTmp] = useState(
    file.dataFormat.format === "ibm" ? 1 : 5
  );
  const [colormapTmp, setColormapTmp] = useState(colormap);

  const handleClose = () => {
    setShowSettings(false);
    setNSamplesTmp(file.nSamples);
    setNTracesPerEnsembleTmp(file.nTracesPerEnsemble);
    setNExtendedTextualHeadersTmp(file.nExtendedTextualHeaders);
    setNTraceHeadersTmp(file.nTraceHeaders);
    setLittleEndianTmp(file.littleEndian);
    setDataFormatTmp(file.dataFormat.format === "ibm" ? 1 : 5);
    setColormapTmp(colormap);
  };

  const handleSaveClose = () => {
    const nSamplesClip = Math.max(1, nSamplesTmp);
    const nTracesPerEnsembleClip = Math.min(
      file.nTraces,
      Math.max(0, nTracesPerEnsembleTmp)
    );
    const nExtendedTextualHeadersClip = Math.max(0, nExtendedTextualHeadersTmp);
    const nTraceHeadersClip = Math.max(1, nTraceHeadersTmp);
    setFile((f) => {
      const newf = new Segy();
      Object.assign(newf, f);
      Object.assign(newf, {
        nSamples: nSamplesClip,
        nTracesPerEnsemble: nTracesPerEnsembleClip,
        nEnsembles: Math.floor(f.nTraces / nTracesPerEnsembleClip),
        nExtendedTextualHeaders: nExtendedTextualHeadersClip,
        nTraceHeaders: nTraceHeadersClip,
        littleEndian: littleEndianTmp,
        dataFormat:
          dataFormatTmp === 1
            ? { bytes: 4, format: "ibm" }
            : { bytes: 4, format: "ieee" },
      });
      return newf;
    });
    setColormap(colormapTmp);
    setShowSettings(false);
    setNSamplesTmp(nSamplesClip);
    setNTracesPerEnsembleTmp(nTracesPerEnsembleClip);
    setNExtendedTextualHeadersTmp(nExtendedTextualHeadersClip);
    setNTraceHeadersTmp(nTraceHeadersClip);
  };

  const handleChangeNSamples = (e) => {
    setNSamplesTmp(e.target.value);
  };

  const handleChangeNTracesPerEnsemble = (e) => {
    setNTracesPerEnsembleTmp(e.target.value);
  };

  const handleChangeNExtendedTextualHeaders = (e) => {
    setNExtendedTextualHeadersTmp(e.target.value);
  };

  const handleChangeNTraceHeaders = (e) => {
    setNTraceHeadersTmp(e.target.value);
  };

  const handleChangeEndian = (e) => {
    setLittleEndianTmp(e.target.value);
  };

  const handleChangeDataFormat = (e) => {
    setDataFormatTmp(e.target.value);
  };

  const handleChangeColormap = (e) => {
    setColormapTmp(e.target.value);
  };

  return (
    <Dialog
      open={showSettings}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="settings"
    >
      <DialogTitle id="settings">Settings</DialogTitle>
      <DialogContent>
        {(nSamplesTmp < 2 || nSamplesTmp > 20000) && (<Alert severity="error">The number of samples must be in [2, 20000]</Alert>)}
        <TextField
          margin="dense"
          label="Number of samples"
          type="number"
          fullWidth
          value={nSamplesTmp}
          onChange={handleChangeNSamples}
        />
        {(nTracesPerEnsembleTmp < 2 || nTracesPerEnsembleTmp > 20000) && (<Alert severity="error">The number of traces per ensemble must be in [2, 20000]</Alert>)}

        <TextField
          margin="dense"
          label="Number of traces per ensemble"
          type="number"
          fullWidth
          value={nTracesPerEnsembleTmp}
          onChange={handleChangeNTracesPerEnsemble}
        />
        <TextField
          margin="dense"
          label="Number of extended textual header blocks"
          type="number"
          fullWidth
          value={nExtendedTextualHeadersTmp}
          onChange={handleChangeNExtendedTextualHeaders}
        />
        <TextField
          margin="dense"
          label="Number of trace header blocks"
          type="number"
          fullWidth
          value={nTraceHeadersTmp}
          onChange={handleChangeNTraceHeaders}
        />
        <Grid container direction="row" spacing={1}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="endian-label">Endian</InputLabel>
              <Select
                labelId="endian-label"
                value={littleEndianTmp}
                onChange={handleChangeEndian}
              >
                <MenuItem value={true}>Little</MenuItem>
                <MenuItem value={false}>Big</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="data-format-label">Data Format</InputLabel>
              <Select
                labelId="data-format-label"
                value={dataFormatTmp}
                onChange={handleChangeDataFormat}
              >
                <MenuItem value={1}>4 byte IBM</MenuItem>
                <MenuItem value={5}>4 byte IEEE</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="colormap-label">Colormap</InputLabel>
              <Select
                labelId="colormap-label"
                value={colormapTmp}
                onChange={handleChangeColormap}
              >
                <MenuItem value={"Greys"}>Greyscale</MenuItem>
                <MenuItem value={"RdBu"}>Red-Blue</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* trace headers to show in hover */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveClose} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
