// prettier-ignore
const e2a = [
    0,  1,  2,  3,  156,9,  134,127,151,141,142, 11,12, 13, 14, 15,
    16, 17, 18, 19, 157,133,8,  135,24, 25, 146,143,28, 29, 30, 31,
    128,129,130,131,132,10, 23, 27, 136,137,138,139,140,5,  6,  7,
    144,145,22, 147,148,149,150,4,  152,153,154,155,20, 21, 158,26,
    32, 160,161,162,163,164,165,166,167,168,91, 46, 60, 40, 43, 33,
    38, 169,170,171,172,173,174,175,176,177,93, 36, 42, 41, 59, 94,
    45, 47, 178,179,180,181,182,183,184,185,124,44, 37, 95, 62, 63,
    186,187,188,189,190,191,192,193,194,96, 58, 35, 64, 39, 61, 34,
    195,97, 98, 99, 100,101,102,103,104,105,196,197,198,199,200,201,
    202,106,107,108,109,110,111,112,113,114,203,204,205,206,207,208,
    209,126,115,116,117,118,119,120,121,122,210,211,212,213,214,215,
    216,217,218,219,220,221,222,223,224,225,226,227,228,229,230,231,
    123,65, 66, 67, 68, 69, 70, 71, 72, 73, 232,233,234,235,236,237,
    125,74, 75, 76, 77, 78, 79, 80, 81, 82, 238,239,240,241,242,243,
    92, 159,83, 84, 85, 86, 87, 88, 89, 90, 244,245,246,247,248,249,
    48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 250,251,252,253,254,255
  ];

const TEXTUAL_FILE_HEADER_BYTES = 3200;
const BINARY_FILE_HEADER_BYTES = 400;
const TRACE_HEADER_BYTES = 240;

const b0 = 3200;
const binaryFileHeaderDefaultMap = [
  { name: "jobId", byte: 3201 - b0, type: "Int32" },
  { name: "lineNum", byte: 3205 - b0, type: "Int32" },
  { name: "reelNum", byte: 3209 - b0, type: "Int32" },
  { name: "nDataTracesPerEnsemble", byte: 3213 - b0, type: "Int16" },
  { name: "nAuxTracesPerEnsemble", byte: 3215 - b0, type: "Int16" },
  { name: "sampleInterval", byte: 3217 - b0, type: "Uint16" },
  { name: "sampleIntervalOriginal", byte: 3219 - b0, type: "Uint16" },
  { name: "nSamplesPerDataTrace", byte: 3221 - b0, type: "Uint16" },
  { name: "nSamplesPerDataTraceOriginal", byte: 3223 - b0, type: "Uint16" },
  { name: "dataFormat", byte: 3225 - b0, type: "Int16" },
  { name: "ensembleFold", byte: 3227 - b0, type: "Int16" },
  { name: "traceSorting", byte: 3229 - b0, type: "Int16" },
  { name: "verticalSum", byte: 3231 - b0, type: "Int16" },
  { name: "sweepFreqStart", byte: 3233 - b0, type: "Int16" },
  { name: "sweepFreqEnd", byte: 3235 - b0, type: "Int16" },
  { name: "sweepLength", byte: 3237 - b0, type: "Int16" },
  { name: "sweepType", byte: 3239 - b0, type: "Int16" },
  { name: "sweepChannelTraceNum", byte: 3241 - b0, type: "Int16" },
  { name: "sweepTaperLengthStart", byte: 3243 - b0, type: "Int16" },
  { name: "sweepTaperLengthEnd", byte: 3245 - b0, type: "Int16" },
  { name: "taperType", byte: 3247 - b0, type: "Int16" },
  { name: "correlated", byte: 3249 - b0, type: "Int16" },
  { name: "binaryGainRecovered", byte: 3251 - b0, type: "Int16" },
  { name: "amplitudeRecoveryMethod", byte: 3253 - b0, type: "Int16" },
  { name: "measurementSystem", byte: 3255 - b0, type: "Int16" },
  { name: "impulseSignalPolarity", byte: 3257 - b0, type: "Int16" },
  { name: "vibratoryPolarityCode", byte: 3259 - b0, type: "Int16" },
  { name: "extNDataTracesPerEnsemble", byte: 3261 - b0, type: "Int32" },
  { name: "extNAuxTracesPerEnsemble", byte: 3265 - b0, type: "Int32" },
  { name: "extNSamplesPerDataTrace", byte: 3269 - b0, type: "Int32" },
  { name: "extSampleInterval", byte: 3273 - b0, type: "Float64" },
  { name: "extSampleIntervalOriginal", byte: 3281 - b0, type: "Float64" },
  { name: "extNSamplesPerDataTraceOriginal", byte: 3289 - b0, type: "Int32" },
  { name: "extEnsembleFold", byte: 3227 - b0, type: "Int32" },
  { name: "endianConstant", byte: 3297 - b0, type: "Uint32" },
  { name: "majorSegyFormatRevisionNum", byte: 3501 - b0, type: "Uint8" },
  { name: "minorSegyFormatRevisionNum", byte: 3502 - b0, type: "Uint8" },
  { name: "fixedLengthTraceFlag", byte: 3503 - b0, type: "Int16" },
  { name: "nExtendedTextualFileHeaderRecords", byte: 3505 - b0, type: "Int16" },
  { name: "maxNAdditionalTraceHeaders", byte: 3507 - b0, type: "Int32" },
  { name: "timeBasisCode", byte: 3511 - b0, type: "Int16" },
  { name: "nTracesInFile", byte: 3513 - b0, type: "BigUint64" },
  { name: "firstTraceOffset", byte: 3521 - b0, type: "BigUint64" },
  { name: "nDataTrailerRecords", byte: 3529 - b0, type: "Int32" },
];

const binaryTraceHeaderDefaultMap = [
  { name: "sequenceNumberWithinLine", byte: 1, type: "Int32" },
  { name: "sequenceNumberWithinFile", byte: 5, type: "Int32" },
  { name: "fieldRecordNumber", byte: 9, type: "Int32" },
  { name: "traceNumberWithinFieldRecord", byte: 13, type: "Int32" },
  { name: "energySourcePointNumber", byte: 17, type: "Int32" },
  { name: "ensembleNumber", byte: 21, type: "Int32" },
  { name: "traceNumberWithinEnsemble", byte: 25, type: "Int32" },
  { name: "traceIdCode", byte: 29, type: "Int16" },
  { name: "nVerticallySummedTraces", byte: 31, type: "Int16" },
  { name: "nHorizontallyStackedTraces", byte: 33, type: "Int16" },
  { name: "dataUse", byte: 35, type: "Int16" },
  { name: "offset", byte: 37, type: "Int32" },
  { name: "receiverGroupElevation", byte: 41, type: "Int32" },
  { name: "surfaceElevationAtSource", byte: 45, type: "Int32" },
  { name: "sourceDepthBelowSurface", byte: 49, type: "Int32" },
  { name: "datumElevationAtReceiverGroup", byte: 53, type: "Int32" },
  { name: "datumElevationAtSource", byte: 57, type: "Int32" },
  { name: "waterColumnHeightAtSource", byte: 61, type: "Int32" },
  { name: "waterColumnHeightAtReceiverGroup", byte: 65, type: "Int32" },
  { name: "elevationScalar", byte: 69, type: "Int16" },
  { name: "coordinateScalar", byte: 71, type: "Int16" },
  { name: "sourceX", byte: 73, type: "Int32" },
  { name: "sourceY", byte: 77, type: "Int32" },
  { name: "receiverGroupX", byte: 81, type: "Int32" },
  { name: "receiverGroupY", byte: 85, type: "Int32" },
  { name: "coordinateUnits", byte: 89, type: "Int16" },
  { name: "weatheringVelocity", byte: 91, type: "Int16" },
  { name: "subweatheringVelocity", byte: 93, type: "Int16" },
  { name: "upholeTimeAtSource", byte: 95, type: "Int16" },
  { name: "upholeTimeAtReceiverGroup", byte: 97, type: "Int16" },
  { name: "sourceStaticCorrection", byte: 99, type: "Int16" },
  { name: "receiverGroupStaticCorrection", byte: 101, type: "Int16" },
  { name: "totalStaticApplied", byte: 103, type: "Int16" },
  { name: "lagTimeA", byte: 105, type: "Int16" },
  { name: "lagTimeB", byte: 107, type: "Int16" },
  { name: "delayRecordingTime", byte: 109, type: "Int16" },
  { name: "muteTimeStart", byte: 111, type: "Int16" },
  { name: "muteTimeEnd", byte: 113, type: "Int16" },
  { name: "nSamples", byte: 115, type: "Uint16" },
  { name: "sampleInterval", byte: 117, type: "Uint16" },
  { name: "gainType", byte: 119, type: "Int16" },
  { name: "instrumentGainConstant", byte: 121, type: "Int16" },
  { name: "instrumentInitialGain", byte: 123, type: "Int16" },
  { name: "correlated", byte: 125, type: "Int16" },
  { name: "sweepFreqStart", byte: 127, type: "Int16" },
  { name: "sweepFreqEnd", byte: 129, type: "Int16" },
  { name: "sweepLength", byte: 131, type: "Int16" },
  { name: "sweepType", byte: 133, type: "Int16" },
  { name: "sweepTaperLengthStart", byte: 135, type: "Int16" },
  { name: "sweepTaperLengthEnd", byte: 137, type: "Int16" },
  { name: "taperType", byte: 139, type: "Int16" },
  { name: "aliasFilterFreq", byte: 141, type: "Int16" },
  { name: "aliasFilterSlope", byte: 143, type: "Int16" },
  { name: "notchFilterFreq", byte: 145, type: "Int16" },
  { name: "notchFilterSlope", byte: 147, type: "Int16" },
  { name: "lowcutFreq", byte: 149, type: "Int16" },
  { name: "highcutFreq", byte: 151, type: "Int16" },
  { name: "lowcutSlope", byte: 153, type: "Int16" },
  { name: "highcutSlope", byte: 155, type: "Int16" },
  { name: "yearRecorded", byte: 157, type: "Int16" },
  { name: "dayOfYear", byte: 159, type: "Int16" },
  { name: "hourOfDay", byte: 161, type: "Int16" },
  { name: "minuteOfHour", byte: 163, type: "Int16" },
  { name: "secondOfMinute", byte: 165, type: "Int16" },
  { name: "timeBasisCode", byte: 167, type: "Int16" },
  { name: "traceWeightingFactor", byte: 169, type: "Int16" },
  { name: "geophoneGroupNumRollSwitchOne", byte: 171, type: "Int16" },
  { name: "geophoneGroupNumFirstTrace", byte: 173, type: "Int16" },
  { name: "geophoneGroupNumLastTrace", byte: 175, type: "Int16" },
  { name: "gapSize", byte: 177, type: "Int16" },
  { name: "overTravel", byte: 179, type: "Int16" },
  { name: "cdpX", byte: 181, type: "Int32" },
  { name: "cdpY", byte: 185, type: "Int32" },
  { name: "inline", byte: 189, type: "Int32" },
  { name: "crossline", byte: 193, type: "Int32" },
  { name: "shotpointNum", byte: 197, type: "Int32" },
  { name: "shotpointNumScalar", byte: 201, type: "Int16" },
  { name: "traceMeasurementUnit", byte: 203, type: "Int16" },
  { name: "transductionConstantMantissa", byte: 205, type: "Int32" },
  { name: "transductionConstantExponent", byte: 209, type: "Int32" },
  { name: "transductionUnit", byte: 211, type: "Int16" },
  { name: "deviceId", byte: 213, type: "Int16" },
  { name: "timeScalar", byte: 215, type: "Int16" },
  { name: "sourceType", byte: 217, type: "Int16" },
  { name: "sourceEnergyDirectionVertical", byte: 219, type: "Int16" },
  { name: "sourceEnergyDirectionCrossline", byte: 221, type: "Int16" },
  { name: "sourceEnergyDirectionInline", byte: 223, type: "Int16" },
  { name: "sourceMeasurementMantissa", byte: 225, type: "Int32" },
  { name: "sourceMeasurementExponent", byte: 229, type: "Int16" },
  { name: "sourceMeasurementUnit", byte: 231, type: "Int16" },
];

const DATA_FORMAT = {
  1: { bytes: 4, format: "ibm" },
  // 2: {bytes: 4, format: 'int'},
  // 3: {bytes: 2, format: 'int'},
  5: { bytes: 4, format: "ieee" },
  // 6: {bytes: 8, format: 'ieee'},
  // 7: {bytes: 3, format: 'int'},
  // 8: {bytes: 1, format: 'int'},
  // 9: {bytes: 8, format: 'int'},
  // 10: {bytes: 4, format: 'uint'},
  // 11: {bytes: 2, format: 'uint'},
  // 12: {bytes: 8, format: 'uint'},
  // 15: {bytes: 3, format: 'uint'},
  // 16: {bytes: 1, format: 'uint'},
};

const readBinaryHeader = (dataview, headers, littleEndian) => {
  const headerValues = {};
  for (const header of headers) {
    headerValues[header.name] = dataview["get" + header.type](
      header.byte - 1,
      littleEndian
    );
  }
  return headerValues;
};

const convertDataFormat = (code) => {
  if (code === 0) return DATA_FORMAT[1];
  return DATA_FORMAT[code];
};

const ibmToIeee = (buffer) => {
  const sign = buffer[0] >> 7;
  const exponent = buffer[0] & 0x7f;
  let fraction = 0;
  function bit(buffer, bit) {
    return (buffer[Math.floor(bit / 8)] >> (7 - (bit % 8))) & 1;
  }
  for (let i = 0; i < 24; i++) {
    fraction += bit(buffer, 8 + i) / (2 << i);
  }
  return (1 - 2 * sign) * Math.pow(16.0, exponent - 64) * fraction;
};

class Segy {
  constructor(file) {
    this.file = file;
    this.textualType = "ebcdic";
    this.littleEndian = false;
    this.textualFileHeader = null;
    this.nExtendedTextualHeaders = 0;
    this.extendedTextualFileHeader = null;
    this.binaryFileHeaderDataview = null;
    this.binaryFileHeaderMap = binaryFileHeaderDefaultMap;
    this.binaryFileHeader = null;
    this.nTraceHeaders = 1;
    this.binaryTraceHeaderMap = binaryTraceHeaderDefaultMap;
  }

  fileHeaderBytes() {
    return (
      TEXTUAL_FILE_HEADER_BYTES * (1 + this.nExtendedTextualHeaders) +
      BINARY_FILE_HEADER_BYTES
    );
  }

  traceHeaderBytes() {
    return TRACE_HEADER_BYTES * this.nTraceHeaders;
  }

  traceDataBytes() {
    return this.nSamples * this.dataFormat.bytes;
  }

  traceBytes() {
    return this.traceHeaderBytes() + this.traceDataBytes();
  }

  traceStartByte(traceId) {
    return this.fileHeaderBytes() + this.traceBytes() * traceId;
  }

  traceDataStartByte(traceId) {
    return (
      this.traceStartByte(traceId) + TRACE_HEADER_BYTES * this.nTraceHeaders
    );
  }

  async init() {
    await this.readBinaryFileHeader();
    this.sampleInterval = this.binaryFileHeader.sampleInterval / 1e6;
    this.nSamples = this.binaryFileHeader.nSamplesPerDataTrace;
    this.dataFormat = convertDataFormat(
        this.binaryFileHeader.dataFormat
      );
    this.nTraces = Math.floor(
      (this.file.size - this.fileHeaderBytes()) / this.traceBytes()
    );
    this.nTracesPerEnsemble = this.binaryFileHeader.nDataTracesPerEnsemble;
    this.nEnsembles = Math.floor(this.nTraces / this.nTracesPerEnsemble);
  }

  async readTextualFileHeader() {
    const slice = this.file.slice(0, TEXTUAL_FILE_HEADER_BYTES);
    if (this.textualType === "ebcdic") {
      const slice8 = new Uint8Array(await slice.arrayBuffer());
      this.textualFileHeader = String.fromCharCode(
        ...slice8.map((s) => e2a[s])
      );
    } else {
      /* Already ASCII */
      this.textualFileHeader = await slice.text();
    }
  }

  async readBinaryFileHeader() {
    if (!this.binaryFileHeaderDataview) {
      this.binaryFileHeaderDataview = new DataView(
        await this.file
          .slice(
            TEXTUAL_FILE_HEADER_BYTES,
            TEXTUAL_FILE_HEADER_BYTES + BINARY_FILE_HEADER_BYTES
          )
          .arrayBuffer()
      );
    }
    const headerValues = readBinaryHeader(
      this.binaryFileHeaderDataview,
      this.binaryFileHeaderMap,
      this.littleEndian
    );
    this.binaryFileHeader = headerValues;
  }

  async readTraceHeader(traceId) {
    const headerStart = this.traceStartByte(traceId);
    const headerEnd = headerStart + this.traceHeaderBytes();
    const dataview = new DataView(
      await this.file.slice(headerStart, headerEnd).arrayBuffer()
    );
    return readBinaryHeader(
      dataview,
      this.binaryTraceHeaderMap,
      this.littleEndian
    );
  }

  async readTraceData(traceId) {
    const dataStart = this.traceDataStartByte(traceId);
    const dataEnd = dataStart + this.traceDataBytes();
    const dataview = new DataView(
      await this.file.slice(dataStart, dataEnd).arrayBuffer()
    );
    const data = new Float32Array(this.nSamples);
    if (this.dataFormat.format === "ieee") {
      for (let i = 0; i < this.nSamples; i++) {
        data[i] = dataview.getFloat32(i * 4, this.littleEndian);
      }
    } else if (this.dataFormat.format === "ibm") {
      //TODO: won't work if syste is big endian
      for (let i = 0; i < this.nSamples; i++) {
        const bytes = [dataview.getUint8(i*4), dataview.getUint8(i*4+1),
          dataview.getUint8(i*4+2), dataview.getUint8(i*4+3)];
        if (this.littleEndian) bytes.reverse();
        data[i] = ibmToIeee(bytes);
      }
    }
    return data;
  }

  async readTracesData(start, end, stride) {
    const nTraces = Math.floor((end - start) / stride);
    const data = new Float32Array(nTraces * this.nSamples);
    for (let traceId = 0; traceId < nTraces; traceId++) {
      const traceData = new Float32Array(data.buffer, traceId * this.nSamples*4,
        this.nSamples);
      const traceIdFile = traceId * stride + start;
      traceData.set(await this.readTraceData(traceIdFile));
    }
    return data;
  }
}

export default Segy;
