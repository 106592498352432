import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PublishIcon from "@material-ui/icons/Publish";
import Segy from "./Segy.js";

const useStyles = makeStyles((theme) => ({
  fileInputButton: {
    margin: theme.spacing(1),
  },
}));

const SelectFile = ({ setFile }) => {
  const fileInput = useRef();
  const classes = useStyles();
  const handleFile = async (e) => {
    const segy = new Segy(e.target.files[0]);
    await segy.init();
    setFile(segy);
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  return (
    <Box textAlign="center">
      <input
        id="file-input-button"
        ref={fileInput}
        type="file"
        accept=".segy,.SEGY,.sgy,.SGY"
        hidden
        onChange={handleFile}
      />
      <label htmlFor="file-input-button">
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<PublishIcon />}
          onClick={handleClick}
          className={classes.fileInputButton}
        >
          Select SEG-Y file
        </Button>
      </label>
    </Box>
  );
};

export default SelectFile;
