import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const FileHeaders = ({ showFileHeaders, setShowFileHeaders, file }) => {
  const textualType = file.textualType;
  const [tabIdx, setTabIdx] = useState(0);

  const handleClose = () => {
    setShowFileHeaders(false);
  };

  useEffect(() => {
    file.readTextualFileHeader();
  }, [file, textualType]);

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (showFileHeaders) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showFileHeaders]);

  function a11yProps(index) {
    return {
      id: `file-headers-tab-${index}`,
      "aria-controls": `file-headers-tabpanel-${index}`,
    };
  }

  const handleTabChange = (e, newValue) => {
    setTabIdx(newValue);
  };

  return (
    <Dialog
      open={showFileHeaders}
      onClose={handleClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
      aria-labelledby="file-headers-title"
      aria-describedby="file-headers-description"
    >
      <DialogTitle id="file-headers-title">File Headers</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <AppBar position="static">
            <Tabs
              value={tabIdx}
              onChange={handleTabChange}
              aria-label="File header tabs"
            >
              <Tab label="Textual" {...a11yProps(0)} />
              <Tab label="Binary" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          {tabIdx === 0 && <TextualHeaders file={file} />}
          {tabIdx === 1 && <BinaryHeaders file={file} />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const TextualHeaders = ({ file }) => {
  return (
    <pre style={{ width: "80ch", whiteSpace: "pre-wrap" }}>
      {file.textualFileHeader}
    </pre>
  );
};

const BinaryHeaders = ({ file }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="Binary file headers table">
        <TableHead>
          <TableRow>
            <TableCell>Header</TableCell>
            <TableCell align="right">Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(file.binaryFileHeader).map(([header, value]) => (
            <TableRow key={header}>
              <TableCell component="th" scope="row">
                {header}
              </TableCell>
              <TableCell align="right">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileHeaders;
